<template>
  <div class="home ma-2">
    <v-card class="ma-2">
      <v-card-title>Até Logo</v-card-title>
      <v-card-text>
        Voce saiu com Sucesso!
      </v-card-text>
      <v-card-actions>
        <v-btn to="/login">Entrar Novamente</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>


export default {
  name: "Logout",
  created(){
    this.$store.dispatch('logOff');
  }
};
</script>
